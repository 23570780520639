// Generated by Framer (4f5e97b)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, getFonts, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";
import CMSActionInterface from "https://framerusercontent.com/modules/2S4XFDGrkqLDHzD4YKsL/xgMO7iTVBdrDTCa6FH2k/CMSActionInterface.js";
import { Icon as Phosphor } from "https://framerusercontent.com/modules/tYScH7LTqUtz5KUaUAYP/CAjjxbTJBxHwH1MagCef/Phosphor.js";
const CMSActionInterfaceFonts = getFonts(CMSActionInterface);
const PhosphorFonts = getFonts(Phosphor);

const cycleOrder = ["iF9CKum3v"];

const serializationHash = "framer-rUgKX"

const variantClassNames = {iF9CKum3v: "framer-v-6psszt"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transitions = {default: {delay: 0, duration: 0.3, ease: [0.12, 0.23, 0.5, 1], type: "tween"}};

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const getProps = ({height, id, slug, width, ...props}) => { return {...props, fztyqXWTo: slug ?? props.fztyqXWTo} }

const createLayoutDependency = (props, variants) => variants.join('-') + props.layoutDependency

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;slug?: string; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale } = useLocaleInfo()

const {style, className, layoutId, variant, fztyqXWTo, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "iF9CKum3v", transitions, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<motion.div initial={variant} animate={variants} onHoverStart={() => setGestureState({isHovered: true})} onHoverEnd={() => setGestureState({isHovered: false})} onTapStart={() => setGestureState({isPressed: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} className={cx("framer-rUgKX", ...sharedStyleClassNames, classNames)} style={{display: "contents"}}>
<Transition value={transition}><motion.div {...restProps} className={cx("framer-6psszt", className)} data-framer-name={"Done"} layoutDependency={layoutDependency} layoutId={"iF9CKum3v"} ref={ref ?? ref1} style={{borderBottomLeftRadius: 70, borderBottomRightRadius: 70, borderTopLeftRadius: 70, borderTopRightRadius: 70, ...style}}><motion.div className={"framer-17u2o5w-container"} layoutDependency={layoutDependency} layoutId={"a8zMkHy4u-container"}><CMSActionInterface height={"100%"} id={"a8zMkHy4u"} layoutId={"a8zMkHy4u"} slug={fztyqXWTo} width={"100%"}/></motion.div><motion.div className={"framer-1szmlbp-container"} layoutDependency={layoutDependency} layoutId={"l6snPcdcr-container"}><Phosphor color={"var(--token-556a66a6-7f4b-4118-b327-ed374e4a0f03, rgb(173, 31, 255)) /* {\"name\":\"Primary\"} */"} height={"100%"} iconSearch={"House"} iconSelection={"Check"} id={"l6snPcdcr"} layoutId={"l6snPcdcr"} mirrored={false} selectByList style={{height: "100%", width: "100%"}} weight={"bold"} width={"100%"}/></motion.div></motion.div></Transition>
</motion.div>
</LayoutGroup>)

});

const css = [".framer-rUgKX [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-rUgKX .framer-1jut7c7 { display: block; }", ".framer-rUgKX .framer-6psszt { align-content: center; align-items: center; display: flex; flex-direction: row; flex-wrap: nowrap; gap: 6px; height: min-content; justify-content: center; overflow: visible; padding: 0px 2px 2px 2px; position: relative; width: min-content; }", ".framer-rUgKX .framer-17u2o5w-container { flex: none; height: auto; left: 0px; position: absolute; top: 0px; width: auto; z-index: 1; }", ".framer-rUgKX .framer-1szmlbp-container { flex: none; height: 20px; position: relative; width: 20px; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-rUgKX .framer-6psszt { gap: 0px; } .framer-rUgKX .framer-6psszt > * { margin: 0px; margin-left: calc(6px / 2); margin-right: calc(6px / 2); } .framer-rUgKX .framer-6psszt > :first-child { margin-left: 0px; } .framer-rUgKX .framer-6psszt > :last-child { margin-right: 0px; } }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 22
 * @framerIntrinsicWidth 24
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["auto","auto"]}}}
 * @framerVariables {"fztyqXWTo":"slug"}
 * @framerImmutableVariables true
 */
const FramerBkru0urzk: React.ComponentType<Props> = withCSS(Component, css, "framer-rUgKX") as typeof Component;
export default FramerBkru0urzk;

FramerBkru0urzk.displayName = "CMS / Lesson Complete Icon";

FramerBkru0urzk.defaultProps = {height: 22, width: 24};

addPropertyControls(FramerBkru0urzk, {fztyqXWTo: {defaultValue: "", title: "Slug", type: ControlType.String}} as any)

addFonts(FramerBkru0urzk, [...CMSActionInterfaceFonts, ...PhosphorFonts])